<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Jobs" to="/jobs/list/1" />
      <q-breadcrumbs-el label="Countries" />
    </q-breadcrumbs>

  <div class="text-h5 q-mt-md">Jobs by Country - Totals</div>

  <div class="row">
    <div v-for="country in countryTotals" :key="country._id" class="col-6 col-sm-4 q-pa-sm">
      <router-link :to="{ name: 'jobCountryList', params: { countryCode: country._id, pageId: 1 }}" class="text-primary text-bold">{{ country.name }}</router-link>: {{country.count}}
    </div>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import jobModule from "../../../store";

// <div v-for="state in stateTotals.sort( (a,b) => ('' + a.name).localeCompare(b.name))" :key="state._id" class="col-4">

export default {
  data() {
    return {
    }
  },
  computed: {
      ...mapState('post', {
        loading: 'loading',
      }),
      countryTotals() {
        return this.$store.getters['job/countryTotalsAlpha'] || [];
      },
  },

  created () {
    this.reqData();
  },

  methods: {
    reqData (force) {
      if (! this.countryTotals || force) {
        this.$store.dispatch( 'job/' + jobModule.actionTypes.LoadTotalsRequest);
      }
    },
  },
}
</script>



